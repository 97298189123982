<script setup>
    import { Head, Link, useForm } from '@inertiajs/vue3';
    import AuthenticationCardLogo from '@/Components/AuthenticationCardLogo.vue';
    import Checkbox from '@/Components/Checkbox.vue';
    import InputError from '@/Components/InputError.vue';
    import InputLabel from '@/Components/InputLabel.vue';
    import PrimaryButton from '@/Components/PrimaryButton.vue';
    import TextInput from '@/Components/TextInput.vue';
    import {onMounted, ref} from "vue";
    import EyeEyeSlash from "@/Components/EyeEyeSlash.vue";

    defineProps({
        canResetPassword: Boolean,
        status: String,
    });

    const form = useForm({
        email: '',
        password: '',
        remember: false,
    });
    const input_mod = ref('password');
    const submit = () => {
        form.transform(data => ({
            ...data,
            remember: form.remember ? 'on' : '',
        })).post(route('login'), {
            onFinish: () => form.reset('password'),
        });
    };
    // Ajouter un état pour contrôler l'affichage du formulaire
    const showForm = ref(false);
    function toogleShowPassowrd(){
        input_mod.value = (input_mod.value==='password')?'text':'password';
    }
    onMounted(() => {
        showForm.value = true; // Afficher le formulaire après le montage du composant
    });
</script>

<template>
    <Head title="Log in" />
    <div class="h-screen flex flex-col items-center justify-center space-y-4 custom-bg-radial">
        <div>
            <transition name="slide-fade" mode="out-in">
                <div
                    v-if="showForm"
                    class="w-1/2 mx-auto"
                >
                    <AuthenticationCardLogo/>
                </div>
            </transition>
            <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
                {{ status }}
            </div>
            <transition name="fade" mode="out-in">
                <form
                    v-if="showForm"
                    @submit.prevent="submit"
                    class="mx-auto w-10/12"
                >
                    <div>
                        <InputLabel for="email" value="Email" class="text-gray-50"/>
                        <TextInput
                            id="email"
                            v-model="form.email"
                            type="email"
                            class="mt-1 block w-full"
                            required
                            autofocus
                            autocomplete="username"
                        />
                        <InputError class="mt-2" :message="form.errors.email" />
                    </div>
                    <div class="mt-4">
                        <InputLabel for="password" value="Password" class="text-gray-50"/>
                        <div
                            class="relative"
                        >
                            <TextInput
                                id="password"
                                v-model="form.password"
                                :type="input_mod"
                                class="mt-1 block w-full"
                                required
                                autocomplete="current-password"
                            />
                            <eye-eye-slash
                                :InputMod="input_mod"
                                @click="toogleShowPassowrd"
                            />
                        </div>

                        <InputError class="mt-2" :message="form.errors.password" />
                    </div>
                    <div class="block mt-4">
                        <label class="flex items-center">
                            <Checkbox v-model:checked="form.remember" name="remember" />
                            <span class="ms-2 text-sm text-gray-50">Remember me</span>
                        </label>
                    </div>
                    <div class="flex items-center justify-end mt-4">
                        <Link v-if="canResetPassword" :href="route('password.request')" class="underline text-sm text-gray-50 hover:text-gray-100 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            Forgot your password?
                        </Link>

                        <PrimaryButton class="ms-4" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                            Log in
                        </PrimaryButton>
                    </div>
                </form>
            </transition>
        </div>
    </div>
</template>

<style>
    /* Animation pour le fondu du formulaire */
    .fade-enter-active, .fade-leave-active {
        transition: opacity 2.7s;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    /* Animation pour faire arriver le logo depuis la gauche */
    .slide-fade-enter-active {
        transition: all 1.7s ease-out;
    }
    .slide-fade-leave-active {
        transition: all 1.7s ease-in;
    }
    .slide-fade-enter-from {
        transform: translateX(-100%);
        opacity: 0;
    }
    .slide-fade-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
</style>